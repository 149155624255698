import React from "react"
import Link from "gatsby-link"

class BursaAnouncementNav extends React.Component {
  state = {
    isActive: false,
  }

  toggleNav = () => {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }))
  }

  render() {
    const { years, currentYear } = this.props
    if (!years) {
      years = []
    }
    return (
      <nav
        className={`navbar calendar-navbar is-pulled-right ${
          this.state.isActive ? "is-active" : ""
        }`}
        role="navigation"
        aria-label="main navigation"
        style={{ zIndex: "999" }}
      >
        <button
          className="button navbar-burger"
          id="calendar-nav-burger"
          data-target="calendarNav"
          onClick={this.toggleNav}
        >
          Year: {currentYear}
        </button>
        <div
          id="calendarNav"
          className={`navbar-menu ${this.state.isActive ? "is-active" : ""}`}
        >
          <div className="navbar-start">
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">Select Year</a>

              <div className="navbar-dropdown">
                {years.map((year, index) => (
                  <a
                    href={`/investorcentre/bursaanouncements?year=${year}`}
                    className="navbar-item"
                    key={index}
                  >
                    {year}
                  </a>
                ))}
                {/* <a href="/investorcentre/calendar" className="navbar-item">
                  2018
                </a>
                <a href="/investorcentre/calendar/2017"className="navbar-item">
                  2017
                </a>
                <a href="/investorcentre/calendar/2016" className="navbar-item">
                  2016
                </a>
                <a href="/investorcentre/calendar/2015" className="navbar-item">
                  2015
                </a>
                <a href="/investorcentre/calendar/2014" className="navbar-item">
                  2014
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default BursaAnouncementNav
