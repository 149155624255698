/**
 * Created by vaibhav on 31/3/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import queryString from "query-string"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css" // This only needs to be imported once in your app

import IRPageWrapper from "../../../layouts/IRPageWrapper"
import BursaAnouncementNav from "../../../components/BursaAnouncementNav"
import IRBG from "../../../components/IRBG"
import IRNav from "../../../components/IRNav"
import HomeLogo from "../../../components/HomeLogo"

export default class BursaPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }

  render() {
    const events = this.props.data.allMarkdownRemark
      ? this.props.data.allMarkdownRemark.edges
      : []
    const query = queryString.parse(this.props.location.search)
    const year = query.year
      ? query.year
      : events.length > 0
      ? events[0].node.frontmatter.year
      : null

    const years = []
    const images = []
    events.map((node) => {
      if (!(years.indexOf(node.node.frontmatter.year) >= 0)) {
        years.push(node.node.frontmatter.year)
      }
      images.push(node.node.frontmatter.image)
    })

    return (
      <IRPageWrapper>
        <IRBG />
        <Helmet>
          <title>Bursa Anouncements - EA Technique</title>
        </Helmet>
        <section
          className="hero is-fullheight"
          style={{ display: `inline-block` }}
        >
          <section className="hero is-small">
            <div className="hero-body">
              <div className="columns">
                <div className="column is-offset-1">
                  <div className="section">
                    <HomeLogo />
                    <h1 className="title" id="title-line">
                      Bursa Anouncements
                    </h1>
                    <IRNav />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section">
            <BursaAnouncementNav years={years} currentYear={year} />
            <div className="columns is-multiline">
              {events.map((node, index) => {
                const event = node.node
                if (event.frontmatter.year == year) {
                  return (
                    <div className="column is-12 is-offset-1" key={index}>
                      <div className="columns" id="calendar-line">
                        <div className="column is-12">
                          <h5>{event.frontmatter.title}</h5>
                          <img
                            src={event.frontmatter.image}
                            onClick={(e) => {
                              e.preventDefault()
                              console.log("tehe")
                              this.setState({
                                photoIndex: index,
                                isOpen: true,
                              })
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )
                }
              })}
              {this.state.isOpen && (
                <Lightbox
                  mainSrc={images[this.state.photoIndex]}
                  nextSrc={images[(this.state.photoIndex + 1) % images.length]}
                  prevSrc={
                    images[
                      (this.state.photoIndex + images.length - 1) %
                        images.length
                    ]
                  }
                  onCloseRequest={() => this.setState({ isOpen: false })}
                  onMovePrevRequest={() =>
                    this.setState({
                      photoIndex:
                        (this.state.photoIndex + images.length - 1) %
                        images.length,
                    })
                  }
                  onMoveNextRequest={() =>
                    this.setState({
                      photoIndex: (this.state.photoIndex + 1) % images.length,
                    })
                  }
                />
              )}
            </div>
          </section>
        </section>
      </IRPageWrapper>
    )
  }
}

BursaPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const bursaPageQuery = graphql`
  query BursaPageQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "bursa-page" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          frontmatter {
            title
            year
            image
            date
          }
        }
      }
    }
  }
`
